

import { Box } from "@mui/material"
import Header1 from "../components/header1"
import AdminSidebar from "../components/adminSideBar"
import PackageComponent from "../components/PackageConfiguration"




const PackageConfig=()=>{







    return <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 120px)" }}>
    <Header1 />
    <Box sx={{ display: "flex", paddingTop: "144px",gap:"24px",mx:"24px" , position:"relative"}}>
        <AdminSidebar />
        <PackageComponent/>
        
    </Box>




</Box>
}

export default PackageConfig