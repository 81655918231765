import { Box,Typography,Grid, Stack, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {updateBank, updateBankModal} from  "../redux/user_reducer"
import { useEffect } from "react";
import axios from "axios";
import CustomAxios from "../utils/CustomAxios";
const BankComponent=()=>{
  const allBank=useSelector(state=>state.user.allBank)
  console.log(allBank)
    const dispatch = useDispatch()
    useEffect(() => {
        
    console.log("??????????????//im hrere")
        fetchBanks();
      }, []);
      const fetchBanks = async () => {
        // try {
        //   const response = await CustomAxios.get('Bank-list/');
        //   console.log(response)
        //   const data = await response.json();
        //   // setBanks(data.netbanking);
        //   // setLoading(false);
        // } catch (err) {
        //   // setError(err);
        //   // setLoading(false);
        // }
      };
      const editBank=(item)=>{
        console.log(item)

        dispatch(updateBankModal(true))
        const obj={id:item.id,account_name:item.account_name,account_number:item.account_number,confirmAcc_number:item.account_number,ifsc_code:item.ifsc_code,bank_name:item.bank_name,branch_name:item.branch_name}
        dispatch(updateBank(obj))
      }

      const handleNewBankForm = ()=>{
        dispatch(updateBank({}))
        dispatch(updateBankModal(true))
      }

    return <Box sx={{ display: "flex", flexDirection: 'column', gap: "16px", width: "100%" }}>
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    <Typography sx={{fontFamily:"Poppins,Roboto",fontWeight:500,lineHeight:"24px",fontSize:"16px",letterSpacing:"0.15px",color:"grey.900"}}>Bank Details</Typography>
        {/* <Typography sx={[formStyle.label, { cursor: "pointer" }]} color="primary" onClick={() => { dispatch(updateAddressModal(true)); dispatch(updateAddress({address_type:typeEnable?"billing":null,title:typeEnable?"Home":null })); }}>+ Add</Typography> */}



        {/* {errors.userName && <Typography sx={{color:"error.light"}}>{errors.userName}</Typography>} */}
    </Box >
    <Grid container sx={{ display: "flex",alignItems:"center"}}>


         {allBank?.map((item,item_id) => {
            return <Grid key={item_id} xs={12} md={4} sm={6} item sx={{ width: { xs: "100%", sm: "100%", md: "100%", padding: "10px",cursor:"pointer" } }} onClick={() => editBank(item)}><Box sm={3} item sx={{ border: "1px solid", padding: "12px", borderColor: "grey.500", borderRadius: "10px" }}>
                {/* <Typography sx={{ fontWeight: "600",textTransform:"capitalize" }}>{item.address_type}</Typography>  */}
                <Stack sx={{flexDirection:"row",justifyContent:"space-between"}}>
                  <Typography sx={{ fontWeight: "600",textTransform:"capitalize"}}>{item.account_name}</Typography>
                </Stack>
                <Typography sx={{ wordBreak: "break-all" }}>Account Number: {item.account_number}</Typography>
                <Typography sx={{ whiteSpace: "pre-wrap",textTransform:"capitalize" }}>IFSC Code: {item.ifsc_code?.toUpperCase()}</Typography>
                <Typography > Bank Name: {item.bank_name?.toUpperCase()} </Typography>

            </Box>
            </Grid>

        })} 
          
        {!allBank && <Grid xs={12} md={4} sm={6} item sx={{ width: { xs: "100%", sm: "100%", md: "100%", padding: "10px",cursor:"pointer" } }} >
          <Skeleton variant="rounded" width={"100%"} height={120} sx={{padding:"12px"}}>
              <Box sm={3} item sx={{ border: "1px solid", padding: "12px", borderColor: "grey.500", borderRadius: "10px"}}>
                  <Stack sx={{flexDirection:"row",justifyContent:"space-between"}}>
                    <Typography sx={{ fontWeight: "600",textTransform:"capitalize"}}>Account Name:</Typography>
                  </Stack>
                  <Typography sx={{ wordBreak: "break-all" }}>Account Number:</Typography>
                  <Typography sx={{ whiteSpace: "pre-wrap",textTransform:"capitalize" }}>IFSC Code: </Typography>
                  <Typography > Bank Name: </Typography>

              </Box>
          </Skeleton>
        </Grid>
        }
        <Grid xs={12} md={4} sm={6} item sx={{ width: { xs: "100%", sm: "100%", md: "100%",padding: "10px" },flexDirection:"columm" }}><Box onClick={() => { handleNewBankForm() }} sm={3} item sx={{border: "1px solid", padding: "12px", borderColor: "primary.light", borderRadius: "10px", display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer",height:"120px"}}>
                <Typography sx={{ fontWeight: "600",textTransform:"capitalize",color:"primary.light" ,textAlign:"center"}} >+</Typography>
                

            </Box>
            </Grid>
    </Grid>

    {/* <Button sx={formStyle.button} variant="contained" >Update</Button> */}
</Box>
}
export default BankComponent