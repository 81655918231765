import { createSlice } from "@reduxjs/toolkit";


let initialState = {
    inventary:false,
    inventoryData:[],
    selectedInventoryProduct:{},
    inventoryHistory:[],
    inventoryForm:false,
    inventoryFormData:{},
    isSettingsPanelExpanded:false,
    adminSettingExpand:false
    
};


let admin = createSlice({
    name: "admin",
    initialState: initialState,
    reducers: {
        updateInventary:(state, { type, payload }) => {
            
            state.inventary=payload
        },
        updateInventoryData:(state, { type, payload }) => {
            
            state.inventoryData=payload
        },
        updateSelectedInventoryProduct:(state, { type, payload }) => {
            
            state.selectedInventoryProduct=payload
        },
        updateInventoryHistory:(state, { type, payload }) => {
            
            state.inventoryHistory=payload
        },
        updateInventoryForm:(state, { type, payload }) => {
            console.log(payload)
            state.inventoryForm=payload
        },
        updateInventoryFormData:(state, { type, payload }) => {
            console.log(payload)
            state.inventoryFormData=payload
        },
        updateAdminReducer:(state,{type,payload})=>{
            state[payload?.type] = payload.value
        }

        
    }
    })

    export let { 
      updateInventary,updateInventoryData,updateSelectedInventoryProduct,updateInventoryHistory,updateInventoryForm,updateInventoryFormData,updateAdminReducer
    } = admin.actions
    
    
    
    export default admin.reducer;